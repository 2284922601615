@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: "Open Sans", sans-serif;
  background-size: contain;
  background-repeat: round;
  background-blend-mode: screen;
  backdrop-filter: blur(30px);
}


a {
  text-decoration: none;
}

.logo {
  border-radius: 50%;
}

.large-logo {
  width: 20rem;
  height: 20rem;
  
}

.speaking {
  border: #43b581 8px solid;
}


.m-r-10 {
  margin-right: 10px;
}

.m-t-xl {
  margin-top: 5rem;
}

.desc {
  font-weight: 500;
  font-size: 1.1em;
}

.bg-secondary {
  background-color: #111111;
}

.heading {
  font-size: 5em;
  font-weight: 800;
}

.part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  padding-bottom: 0;
}

.scroll-arrows {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.xl-font {
  font-size: 5em;
}

.hover-event {
  cursor: pointer;
  transition: 500ms all ease;
  transform: scale(1);
}

.hover-event:hover{
  transform: scale(1.1);
}

@keyframes move {
  0% {transform: translateY(-10%);}
  50% {transform: translateY(10%);}
  100% {transform: translateY(-10%);}
}

.arrows {
  width: 50%;
  margin-top: 20%;
  animation: move 2s ease infinite; 
  cursor: pointer;
}


mark {
  background: #5765ec;
  color: #fff;
}

.cards {
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  width: 90vw;
}

.card {
  border-radius: 1.3em;
  width: 1fr;
  height: 100%;
  cursor: pointer;
  transition: all 500ms ease;
  transform: scale(1);
}

.no-hover {
  cursor: default;
}


.weight-500 {
  font-weight: 500;
}

.card:hover {
  transform: scale(1.1);
}

.no-hover:hover {
  transform: scale(1);
}

.card-img-top {
  border-radius: 1.3em 1.3em 0 0;
}

.text-right {
  text-align: right;
  padding: 2%;
  border-radius: 2em;
  margin-left: 30%;
  margin-right: 1%;
  width: fit-content;
  margin-bottom: 2%;
}

.text-left {
  text-align: left;
  align-items: left;
  padding: 2%;
  border-radius: 2em;
  margin-left: 1%;
  width: fit-content;
  margin-right: 30%;
  margin-bottom: 2%;
}

.weight-400 {
  font-weight: 400;
}

.message {
  font-size: 1.4em;
}

.message-right {
  text-indent: 10%;
}

.message-left {
  width: 100%;
}

.title-right{ 
  margin-right: 5%;
}

.title-left{ 
  margin-left: 5%;
}
.footer {
  width: 100%;
  margin-top: 5%;
  font-size: 2em;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.footer-black {
  background-color: #000000;
  font-size: 1em;
  color: #a6a6a6;
  text-align: left;
  cursor: default;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(4, auto);
  text-align: center;
}

.footer-link{
  cursor: "pointer";
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 3s ease;
}
.head-title {
  font-size: 3.2em;
  margin-bottom: 1%;
}



@media (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .arrows {
    margin-top: 0;
    width: 30%;
  }

  .card:hover {
    transform: scale(1.05);
  }

  .text-left, .text-right {
    text-align: center;
    margin: 0;
    margin-bottom: 10%;
  }

  .head-title {
    font-size: 3.2em;
    margin-bottom: 5%;
  }

  .message {
    font-size: 1.3em;
  }
  .hover-event:hover {
    transform: scale(1.05);
  }
  .footer {
    margin-top: 40%;
  }

  .footer-links {
    margin-right: 0;
    margin-left: 0;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
  }
}
